/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */
import { createGlobalStyle } from "styled-components"
import mediaQuery from "../utils/media"
import * as React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import Header from "./header"
import Footer from "./footer"
import SEO from "./seo"
//@ts-ignore
import logo from "../images/logo_128x128.png"

const GlobalStyle = createGlobalStyle`
  body, button, div, span {
    font-family: Cabin;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 150%;
  }
  
  body, button {
    background-color: #e5f3ff;
  }

  body {
    z-index: -100;
    margin-left: 0px;
    margin-right: 0px; 
  }
  
  h1, h2, h3, h4, h5, h6 {
    font-weight: bold;
    margin-bottom: 10px;
  }

  h1 {
    font-style: normal;
    font-weight: normal;
    font-size: 45px;
    line-height: 55px;
    letter-spacing: 0.02em;

    ${mediaQuery.down("md")`
    font-size: 35px;
    line-height: 45px;
    `}

  }

  h2 {
    font-size: 38px;
    line-height: 36px;
    font-weight: 300;
    text-shadow: none;
  }

  `

interface Layout {
  children: React.ReactNode
}

const Layout = ({ children }: Layout) => {
  return (
    <>
      <SEO meta={[]} lang="en-us" />
      <GlobalStyle />
      <Header />
      {children}
      <Footer />
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
