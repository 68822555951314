import * as React from "react"
import Link from "./link"
import PropTypes from "prop-types"
import styled from "styled-components"
import Button from "./button"
import Content from "./content"
import media from "../utils/media"
// @ts-ignore
import LogoSvg from "../images/logo.svg"

interface Header {}

const Section = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 20px 0px;
`

const HeaderStyled = styled(Content)`
  display: flex;
  flex-direction: row;
  height: 86px;
  left: 0px;
  top: 0px;
`
const HeaderContent = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`

const Logo = styled.img`
  width: 64px;
`

const HeaderButton = styled(Button)`
  ${media.down("md")`
  width: 87px;
  height: 59px;
  `}
`

const Header = ({}: Header) => (
  <HeaderStyled>
    <HeaderContent>
      <Section>
        <Logo src={LogoSvg} alt={"UnifiedAI Icon"} />
      </Section>
      <Section>
        <Link to={"#useCases"}>
          <HeaderButton showBorder={false}>Use Cases</HeaderButton>
        </Link>
        <Link target={"_blank"} to={"https://blog.getunifiedai.com/"}>
          <HeaderButton showBorder={false}>Blog</HeaderButton>
        </Link>
        <Link
          target={"_blank"}
          to={"https://georgem3.typeform.com/to/qUPk9yH9"}
        >
          <HeaderButton showBorder={true}>Get access</HeaderButton>
        </Link>
      </Section>
    </HeaderContent>
  </HeaderStyled>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
