import styled from "styled-components"
import media from "../utils/media"

const Content = styled.div`
  padding-right: 12%;
  padding-left: 12%;
  max-width: 1800px;

  ${media.down("xl")`
  padding-right: 32px;
  padding-left: 32px;
  `}

  ${media.down("md")`
  padding-right: 6%;
  padding-left: 6%;
  `}

  ${media.down("sm")`
  padding-right: 12px;
  padding-left: 12px;
  `}
`

export default Content
