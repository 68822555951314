import { css, CSSObject, CSSProp } from "styled-components"

type mediaSize = "xs" | "sm" | "md" | "lg" | "xl"
type CssStyle = CSSObject | CSSProp
// Same as Bootstrap breakpoints
// @see https://getbootstrap.com/docs/4.0/layout/overview/#responsive-breakpoints
export const xs = 0
export const sm = 576
export const md = 768
export const lg = 992
export const xl = 1200

export const breakpoints: Record<mediaSize, string> = {
  xs: `${xs}px`,
  sm: `${sm}px`,
  md: `${md}px`,
  lg: `${lg}px`,
  xl: `${xl}px`,
}

const breakpointsNumber: Record<mediaSize, number> = {
  xs,
  sm,
  md,
  lg,
  xl,
}

export const up = (size: mediaSize) => (styles: CssStyle) => {
  const _size = breakpointsNumber[size]
  return css`
    @media (min-width: ${_size}px) {
      ${styles}
    } ;
  `
}

export const down = (size: mediaSize) => (styles: CssStyle) => {
  const _size = breakpointsNumber[size]
  return css`
    @media (max-width: ${_size - 1}px) {
      ${styles}
    } ;
  `
}

export const between =
  (min: mediaSize, max: mediaSize) => (styles: CssStyle) => {
    const _min = breakpointsNumber[min]
    const _max = breakpointsNumber[max]
    return css`
      @media (min-width: ${_min}px) and (max-width: ${_max - 1}px) {
        ${styles}
      } ;
    `
  }
export default {
  up,
  down,
  between,
  breakpoints,
}
