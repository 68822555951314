import React from "react"
import styled from "styled-components"
import Content from "../content"
import Link from "../link"
import media from "../../utils/media"
//@ts-ignore
import TwitterSvg from "../../images/Twitter Logo.svg"
//@ts-ignore
import EmailSvg from "../../images/email_icon.svg"
//@ts-ignore
import LinkedInSvg from "../../images/linkedIn_logo.svg"

const FooterContent = styled(Content)`
  background-color: #101d42;
`
const Section = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-even;
  align-items: center;
  min-height: 30vh;

  div,
  span,
  p {
    color: #fff;
  }

  ${media.down("md")`
  flex-direction: column;
  justify-content: center;
  row-gap:32px;
  
  width:100%;
  padding-top:16px;
  padding-bottom:16px;
  `}
`
interface IColumn {
  side: "left" | "right"
}
const Column = styled.div<IColumn>`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: ${({ side }) => (side === "left" ? "flex-start" : "flex-end")};
  ${media.down("md")`
  align-items: center;
  `}
`

const CompanyText = styled.span`
  font-size: 36px;
  line-height: 46px;
`

const Subtitle = styled.span`
  line-height: 27px;
`
const Copyright = styled.span`
  font-size: 18px;
  line-height: 27px;
`
const ConnectText = styled.span`
  font-size: 24px;
  line-height: 36px;
`

const ContactSection = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: 60px;
  grid-column-gap: 16px;
  width: 168px;
`
const ContactIcon = styled.img`
  height: 100%;
  width: 100%;
`

const Footer: React.FC = ({}) => {
  return (
    <FooterContent>
      <Section>
        <Column side={"left"}>
          <CompanyText>{"UnifiedAI"}</CompanyText>
          <Subtitle>{"AI for everyone."}</Subtitle>
          <Copyright>{"Copyright © 2021 UnifiedAI"}</Copyright>
        </Column>
        <Column side={"right"}>
          <ConnectText>{"Connect with us"}</ConnectText>
          <ContactSection>
            <a
              target={"_blank"}
              href={`mailto:hello@getunifiedai.com?subject=%F0%9F%91%8B%20Let's%20talk!`}
            >
              <ContactIcon src={EmailSvg} />
            </a>
            <Link target={"_blank"} to={`https://twitter.com/unified_ai`}>
              <ContactIcon src={TwitterSvg} />
            </Link>
            <Link
              target={"_blank"}
              to={`https://in.linkedin.com/company/unifiedai`}
            >
              <ContactIcon src={LinkedInSvg} />
            </Link>
          </ContactSection>
        </Column>
      </Section>
    </FooterContent>
  )
}

export default Footer
