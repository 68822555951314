import styled from "styled-components"

interface IButton {
  showBorder?: boolean
}

const Button = styled.button<IButton>`
  width: 157px;
  height: 59px;
  left: 215px;
  top: 13.5px;
  cursor: pointer;
  border: ${({ showBorder }) => (showBorder ? "2px solid #000000" : "none")};
  border-radius: 15px;
  background-color: inherit;
`

export default Button
